import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import './Footer.css';


function Footer() {
  const d = new Date();
  const current_year = d.getFullYear();
  function activeMenu(id,arrow) {
    var el = document.getElementById(id);
    var ar = document.getElementById(arrow);
    if(el.classList.contains('active')) {
      el.classList.remove('active');
      ar.classList.remove('arrow_turn')
    }
    else {
      el.classList.add('active');
      ar.classList.add('arrow_turn')
    }
    
  }

  return (
    <div className='footer-container'>
      <div className='footer1'>
        <Row className='g-0'>
          <Col md={4} lg={3} className='footer-link-items'>
            <div className='footer-menu' onClick={()=>activeMenu('footer-1','footer-1-arrow')}>
              <h2>PRODUCTS AND SERVICES<span id='footer-1-arrow' className="fa-solid fa-chevron-down right"></span></h2>
            </div>
            <div id='footer-1' className='footer-menu-items'>
              <a href='https://www.causewaylink.com.my/routes-schedules/singapore-cross-border-bus/'>Singapore Bus</a>
              <a href='https://www.causewaylink.com.my/routes-schedules/local-bus/'>Local Bus</a>
              <a href='https://www.causewaylink.com.my/routes-schedules/airport-shuttle-bus/'>Airport Shuttle Bus</a>
              <a href='https://www.causewaylink.com.my/routes-schedules/mybas/'>myBAS</a>
              <a href='https://www.causewaylink.com.my/routes-schedules/bus-muafakat/'>Bus Muafakat</a>
              <a href='https://causewaylink.com.my/express/'>Express Bus</a>
              <a href='https://www.causewaylink.com.my/routes-schedules/shopping-leisure-bus/'>Shopping & Leisure Bus</a>
              <a href='https://www.causewaylink.com.my/holiday/bus-pass/pass-details/?id=1050-Travel-Bus-Pass'>Travel Bus Pass</a>
              <a href='https://www.causewaylink.com.my/products-and-services/bus-advertising/'>Bus Advertising</a>
            </div>
            <div className='footer-menu' onClick={()=>activeMenu('footer-2','footer-2-arrow')}>
              <h2>BUS RENTAL<span id='footer-2-arrow' className="fa-solid fa-chevron-down right"></span></h2>
            </div>
            <div id='footer-2' className='footer-menu-items'>
              <a href='https://www.causewaylink.com.my/bus-rental/shuttle-bus/'>Shuttle Bus</a>
              <a href='https://www.causewaylink.com.my/bus-rental/exhibition-bus/'>Exhibition Bus</a>
              <a href='https://www.causewaylink.com.my/bus-rental/factory-bus/'>Factory Bus</a>
              <a href='https://www.causewaylink.com.my/bus-rental/coach-hire/'>Coach Hire</a>
              <a href='https://www.causewaylink.com.my/bus-rental/charter-bus-rental-services/'>Charter Bus Rental Services</a>
            </div>
          </Col>
          <Col md={4} lg={3} className='footer-link-items'>
            <div className='footer-menu' onClick={()=>activeMenu('footer-3','footer-3-arrow')}>
              <h2>BUSINESS<span id='footer-3-arrow' className="fa-solid fa-chevron-down right"></span></h2>
            </div>
            <div id='footer-3' className='footer-menu-items'>
              <a href='https://www.causewaylink.com.my/business/business-partnership/'>Business Partnership</a>
              <a href='https://www.causewaylink.com.my/business/terminal-management/'>Terminal Management</a>
            </div>
            <div className='footer-menu' onClick={()=>activeMenu('footer-4','footer-4-arrow')}>
              <h2>PARTNERS<span id='footer-4-arrow' className="fa-solid fa-chevron-down right"></span></h2>
            </div>
            <div id='footer-4' className='footer-menu-items'>
              <a href='https://www.causewaylink.com.my/holiday/'>Causeway Link Holidays</a>
              <a href='https://www.manjalink.com.my/'>ManjaLink</a>
              <a href='https://lugo.live/'>LUGO</a>
              <a href='http://manjalabs.com/'>Manja Labs</a>
              <a href='https://manjastudios.com/'>Manja Studio</a>
              <a href='https://www.facebook.com/people/Hipgraphy-Advertising-Sdn-Bhd/100057621963867/'>Hipgraphy</a>
              <a href='https://kumpool.com.my/'>Kumpool</a>
              <a href='https://www.manja.my/'>ManjaStore</a>
            </div>
          </Col>
          <Col md={4} lg={3} className='footer-link-items'>
            <div className='footer-menu' onClick={()=>activeMenu('footer-5','footer-5-arrow')}>
              <h2>COMPANY INFORMATION<span id='footer-5-arrow' className="fa-solid fa-chevron-down right"></span></h2>
            </div>
            <div id='footer-5' className='footer-menu-items'>
              <a href='https://www.causewaylink.com.my/company-information/about-us/'>About Us</a>
              <a href='https://www.causewaylink.com.my/company-information/mission-vision-values/'>Mission, Vision & Values</a>
              <a href='https://www.causewaylink.com.my/company-information/milestone/'>Milestone</a>
              <a href='https://www.causewaylink.com.my/company-information/careers/'>Careers</a>
              <a href='https://www.causewaylink.com.my/category/news/'>News</a>
              <a href='https://www.causewaylink.com.my/company-information/counter-locations/'>Counter Locations</a>
              <a href='https://www.causewaylink.com.my/company-information/contact-us/'>Contact Us</a>
            </div>
            <div className='footer-menu' onClick={()=>activeMenu('footer-6','footer-6-arrow')}>
              <h2>OTHERS INFORMATION<span id='footer-6-arrow' className="fa-solid fa-chevron-down right"></span></h2>
            </div>
            <div id='footer-6' className='footer-menu-items'>
              <a href='https://www.causewaylink.com.my/help/'>FAQs</a>
              <a href='https://www.causewaylink.com.my/category/travel-guide/'>Travel Guide</a>
              <a href='https://www.causewaylink.com.my/category/destination-guide/'>Destination Guide</a>
              <a href='https://www.causewaylink.com.my/category/promotions/'>Promotions</a>
              <a href='https://www.causewaylink.com.my/others-information/loyalty-program/'>Loyalty Program</a>
            </div>
          </Col>
          <Col md={12} lg={3} className='footer-link-items footer-images'>
            <Row className='g-0'>
              <Col md={3} lg={12} className='footer-menu'>
                <h2>COMPANIES</h2>
                <img src='/images/img_handal_ceria.jpg' />
                <img src='/images/img_handal_indah.jpg' />
              </Col>
              <Col md={5} lg={12} className='footer-menu'>
                <h2>AWARDS</h2>
                <p>
                  <img src='/images/img_bizSAFE-Enterprise-Level-3.png' />
                  <img src='/images/img_brand.png' />
                  <img src='/images/img_SME100.png' />
                  <img src='/images/img_soe-300x150-1.png' />
                  <img src='/images/img_top-100-singapore-elite-enterprise-2015-2016.png' />
                </p>
              </Col>
              <Col md={4} lg={12} className='footer-menu'>
                <h2>Powered by LUGO App</h2>
                <a href='https://rem7j.app.goo.gl/qYU7' target='_blank'>
                  <img className='powered' src='images/img_store.png'/>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/'>Milestone</Link>
            <Link to='/'>Our Mission</Link>
            <Link to='/'>Press Room</Link>
            <Link to='/'>Event</Link>
            <Link to='/'>Group Companies</Link>
            <Link to='/'>Leadership</Link>
            <Link to='/'>Awards</Link>
            <Link to='/'>Careers</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Where We Covered</h2>
            <Link to='/'>Route Map</Link>
            <Link to='/'>Johor</Link>
            <Link to='/'>Singapore</Link>
            <Link to='/'>Kuala Lumpur</Link>
            <Link to='/'>Penang</Link>
            <Link to='/'>Melaka</Link>
            <Link to='/'>Ipoh</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Booking</h2>
            <Link to='/'>Book Bus</Link>
            <Link to='/'>Travel</Link>
            <Link to='/'>Plan Trip</Link>
            <Link to='/'>Search for Bus</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Manage</h2>
            <Link to='/'>Manage your booking</Link>
            <Link to='/'>Travel Updates</Link>
            <Link to='/'>Travel Notice</Link>
            <Link to='/'>Before the trip</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Loyalty</h2>
            <Link to='/'>ManjaLink</Link>
            <Link to='/'>Join ManjaLink</Link>
            <Link to='/'>Our partners</Link>
            <Link to='/'>Benefits</Link>
          </div>
        </div>
      </div> */}
      <div className='footer-copyright-container'>
        <div className='footer-subcontainer'>
          <Row className='m-0'>
            <Col className='middle' lg={{ span: 2,order: 'last' }}>
              <div className='footer-icon-center'>
                <a className='footer-icon' href="https://www.facebook.com/causewaylink/" target="_blank">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a className='footer-icon' href="https://twitter.com/CausewayLinkMY" target="_blank">
                  <i class="fa-brands fa-twitter"></i>
                </a>
                <a className='footer-icon' href="https://www.instagram.com/causewaylink/" target="_blank">
                  <i class="fa-brands fa-instagram"></i>
                </a>
                <a className='footer-icon' href="https://www.linkedin.com/company/handal-indah-sdn-bhd" target="_blank">
                  <i class="fa-brands fa-linkedin-in"></i>
                </a>
                <a className='footer-icon' href="https://www.youtube.com/channel/UCg62syI04abhyfQwgnDdq6g" target="_blank">
                  <i class="fa-brands fa-youtube"></i>
                </a>
                <a className='footer-icon' href="mailto:feedback@support.causewaylink.com.my" target="_blank">
                  <i class="fa fa-envelope"></i>
                </a>
                {/* <img className='mt-1 footer-icon' src='images/ic_fb.png'></img>
                <img className='mt-1 footer-icon' src='images/ic_twitter.png'></img>
                <img className='mt-1 footer-icon' src='images/ic_googleplus.png'></img>
                <img className='mt-1 footer-icon' src='images/ic_insta.png'></img>
                <img className='mt-1 footer-icon' src='images/ic_email.png'></img> */}
              </div>
            </Col>
            <Col lg={5}>
              <div className='copyright-handal-link border-left'>
                <a href='https://www.causewaylink.com.my/help/knowledge-base/causeway-link-website-disclaimers/' className='copyright-handal-link'>Legal</a>
                <a href='https://www.causewaylink.com.my/help/knowledge-base/causeway-link-privacy-policy/' className='copyright-handal-link'>Privacy Policy</a>
                <a href='https://www.causewaylink.com.my/help/knowledge-base/causeway-link-cookies-policy/' className='copyright-handal-link'>Cookies Policy</a>
                <a href='https://www.causewaylink.com.my/help/knowledge-base/terms-and-conditions/' className='copyright-handal-link'>Terms & Condition</a>
              </div>
            </Col>
            <Col lg={{ span: 5,order: 'first' }}>
              <div className='copyright-handal'>
                Copyright © Handal Indah 2006-{current_year}. All Rights Reserved.
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Footer;
