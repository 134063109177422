import React, { useState, useContext } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { checkIsReschedule, getSelectedAddOnData, getToken, tokenIsValid, getUserPrimaryEmail } from './Function';
import './LinkButton.css';
// import { TotalBusFareContext, BusFareDepartContext, BusFareReturnContext, BusFareAddOnContext, LoyaltyDiscountContext  } from '../UserContext';
import { LoyaltyDiscountContext, LoyaltyIDContext, VoucherCodeContext } from '../UserContext';
import { LoadingScreenModal } from './LoadingScreenModal';
import * as CryptoJS from 'crypto-js';

export const CompletedBookingButton = ({
	guests,
	primary_guest_contact,
	primary_guest_email,
	depart,
	destination,
	depart_date,
	return_date,
	passenger_count,
	depart_trip_key,
	return_trip_key,
	currency
}) => {

	const children = 'Complete Booking';
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const { LoyaltyID, setLoyaltyID } = useContext(LoyaltyIDContext);
	const { TotalLoyalty, setLoyalty } = useContext(LoyaltyDiscountContext);
	const { VoucherCode, setVoucherCode } = useContext(VoucherCodeContext);
	const token = getToken();
	const checkReschedule = checkIsReschedule();
	const [loading, setLoading] = useState(false);
	let TotalBusFare = parseFloat(sessionStorage.getItem("busFare")).toFixed(2);

	let selected_origin = sessionStorage.getItem("Origin_data");
	var _obj_selected_origin = JSON.parse(selected_origin);

	let original_addon_data = getSelectedAddOnData();
	let original_addon_sessionID = localStorage.getItem("addOnSession");

	// console.log(original_addon_data);
	// console.log(original_addon_sessionID);
	// console.log(TotalBusFare);
	// console.log(_obj_selected_origin);


	console.log(`depart = ${depart}`);
	console.log(`destination = ${destination}`);


	let rescheduleAmount = sessionStorage.getItem("rescheduleAmount");
	let _depart_data = JSON.parse(sessionStorage.getItem("departData"));
	let _return_data = JSON.parse(sessionStorage.getItem("returnData"));

	// console.dir(_depart_data);
	// console.dir(_return_data);
	// console.log(VoucherCode)
	// // console.log(_depart_data.duration);
	// // console.log(_return_data.duration);

	let selected_destination = sessionStorage.getItem("Destination_data");
	var _obj_selected_destination = JSON.parse(selected_destination);

	// console.log("selected_destination = " + selected_destination);
	//{"CountryCode":"MY","CountryName":"Malaysia","Importance":0,"IsCapital":false,"IsOrigin":true,"Latitude":"3.077930","PlaceId":2,"PlaceName":"Kuala Lumpur","PlaceUniqueShortName":"kualalumpur","SubPlaceId":246,"SubPlaceName":"TBS (Terminal Bersepadu Selatan)","SubPlaceUniqueShortName":"tbsterminalbersepaduselatan"}
	// const {BusFareDepart, setBusFareDepart} = useContext(BusFareDepartContext);




	const redirectToPaymentGateway = (event) => {
		tokenIsValid();
		// console.log(VoucherCode)
		// if(VoucherCode !== null && VoucherCode !== '') {
		//   const voucherValid = checkVoucherCode(VoucherCode);
		//   if(voucherValid == true) {

		//   }
		// }
		if (sessionStorage.getItem("departData") == null) {
			alert("Error while checkout");
			window.location.href = '/';
		}
		let addon_sessionID = localStorage.getItem("addOnSession");
		let addon_data = getSelectedAddOnData();
		if (JSON.stringify(addon_data) !== JSON.stringify(original_addon_data)) {
			alert("Your addon data has been modified. Please try again.");
			window.location.reload();
		}
		// console.log(addon_sessionID);
		// console.log(addon_data);

		// console.log('guests');
		// console.log('-----------');
		// console.dir(guests);
		// console.log('-----------');

		var depart_guests = [];
		var return_guests = [];
		var _submit = true;

		if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(primary_guest_email)) == false) {
			event.preventDefault();
			alert('Please enter your email in format: yourname@example.com')
			_submit = false;
			return;
		}

		if (primary_guest_contact == null || primary_guest_contact.trim() == '') {
			event.preventDefault();

			alert('Please enter your phone number')
			_submit = false;
			return;
		}


		if ((/^-?\d+$/.test(primary_guest_contact)) == false) {
			event.preventDefault();

			alert('Please enter your phone number in numeric format')
			_submit = false;
			return;
		}


		if (guests.length < passenger_count) {
			event.preventDefault();
			alert('All fields are mandatory');
			_submit = false;
			return;
		} else {

			guests.forEach(element => {
				// const [key, value] = element;
				// console.log(element);

				if (!element.ticket_type) {
					element.ticket_type = 'A';
					// event.preventDefault();
					// alert(`Guest ${element.guest} Ticket Type is required`);
					// return;
				}

				if (!element.guest_name) {
					event.preventDefault();
					alert(`Guest ${element.guest} Full Name is required`);
					_submit = false;
					return;
				}


				if (!element.passport && !element.ic) {
					event.preventDefault();
					alert(`Guest ${element.guest} either Passport or IC is required`);
					_submit = false;
					return;
				}

				if (!element.dob) {
					event.preventDefault();
					alert(`Guest ${element.guest} Date of Birth is required`);
					_submit = false;
					return;
				}

				if (!element.gender) {
					event.preventDefault();
					alert(`Guest ${element.guest} Gender is required`);
					_submit = false;
					return;
				}

				if (!element.nationality) {
					event.preventDefault();
					alert(`Guest ${element.guest} Nationality is required`);
					_submit = false;
					return;
				}


				if (element.depart_seat_id) {

					depart_guests.push(
						{
							ticket_type: element.ticket_type,
							guest: element.guest,
							depart_display_code: element.depart_display_code,
							depart_seat_id: element.depart_seat_id,
							depart_seat_number: element.depart_seat_number,
							depart_seat_code: element.depart_seat_code,

							guest_name: element.guest_name,
							ic: element.ic,
							passport: element.passport,
							passport_expiry_date: element.passport_expiry_date,
							dob: element.dob,
							gender: element.gender,
							nationality: element.nationality
						}
					);
				}

				if (element.return_seat_id) {

					return_guests.push(
						{
							ticket_type: element.ticket_type,
							guest: element.guest,
							return_display_code: element.return_display_code,
							return_seat_id: element.return_seat_id,
							return_seat_number: element.return_seat_number,
							return_seat_code: element.return_seat_code,

							guest_name: element.guest_name,
							ic: element.ic,
							passport: element.passport,
							passport_expiry_date: element.passport_expiry_date,
							dob: element.dob,
							gender: element.gender,
							nationality: element.nationality
						}
					);
				}
			});


			if (_submit) {

				setLoading(true)

				// Simple POST request with a JSON body using fetch
				const requestOptions = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': token
					},

					body: JSON.stringify({
						Amount: `${TotalBusFare}`,//min payment amount
						CustName: `${depart_guests[0].guest_name}`,
						CustEmail: `${primary_guest_email}`,
						CustPhone: `${primary_guest_contact}`,
						// Details: `${depart} - ${destination}`,
						Details: `${_obj_selected_origin.subplacename} - ${_obj_selected_destination.subplacename}`,

						CurrencyID: `${currency}`,

						DepartDate: `${depart_date}`,
						//eg: departure_time:"2022-11-05 15:00"
						DepartTime: `${_depart_data.departure_time.split(' ')[1]}`,
						DepartGuests: JSON.stringify(depart_guests),
						DepartTripKey: `${depart_trip_key}`,

						DepartPlaceId: _obj_selected_origin.placeid,
						DepartPlaceName: _obj_selected_origin.placename,
						DepartSubPlaceId: _obj_selected_origin.subplaceid,
						DepartSubPlaceName: _obj_selected_origin.subplacename,
						// DepartSubPlaceName: `${depart}`,
						DepartDuration: `${_depart_data.duration}`,

						ReturnDate: `${(return_date == null || return_date === 'null') ? '' : return_date}`,
						ReturnTime: `${(return_date == null || return_date === 'null') ? '' : _return_data?.departure_time != null ? _return_data.departure_time.split(' ')[1] : ''}`,
						ReturnGuests: (return_guests.length == 0) ? [] : JSON.stringify(return_guests),
						ReturnTripKey: (return_trip_key == null) ? null : `${return_trip_key}`,

						ReturnPlaceId: _obj_selected_destination.placeid,
						ReturnPlaceName: _obj_selected_destination.placename,
						ReturnSubPlaceId: _obj_selected_destination.subplaceid,
						// ReturnSubPlaceName: `${destination}`,
						ReturnSubPlaceName: _obj_selected_destination.subplacename,
						ReturnDuration: (_return_data != null ? _return_data?.duration : null),

						RescheduleAmount: rescheduleAmount,
						OldCartGuid: _obj_selected_origin.OldCartGuid,
						VoucherCode: VoucherCode,
						NoOfPax: passenger_count,
						SessionID: addon_sessionID,
						LoyaltyID: LoyaltyID != "0" ? LoyaltyID : null,
						AddOn: (addon_data != null && addon_data != [] && addon_data != 'null' && addon_data != '[]') ? (addon_data) : null
					})
				};

				// console.log('add on data');
				// console.log('---------------');
				// console.dir(addon_data);
				if (LoyaltyID != null && LoyaltyID != "0") {

					// checkout with loyalty points
					const MANJA_URL = process.env.REACT_APP_MANJA_REWARD_URL;
					const API_KEY = process.env.REACT_APP_MANJA_REWARD_KEY;
					const STORE_CODE = process.env.REACT_APP_MANJA_REWARD_STORED_CODE;
					const userPrimaryEmail = getUserPrimaryEmail();
					let url = `${MANJA_URL}/Rewards/${STORE_CODE}/${userPrimaryEmail}/2`;
					const getLoyaltyData = async () => {
						try {
							const response = await fetch(
								url,
								{
									method: 'GET',
									mode: 'cors',
									headers: {
										'apikey': API_KEY,
										'Authorization': getToken()
									}
								}
							);
							if (!response.ok) {
								throw new Error(
									`This is an HTTP error: The status is ${response.status}`
								);
							}
							let actualData = await response.json();
							// console.log(actualData);
							//check if including LoyaltyID, proceed to payment

							const itemToCheck = LoyaltyID;

							const itemExists = actualData.rewards.some(item => item.id == itemToCheck);

							if (itemExists) {
								fetch(`${SERVER_URL}/order/new`, requestOptions)
									.then(response =>
										response.json()
									)
									.then(data => {
										// console.log(data)
										if (data.merchantreferencecode != null && data.url != null) {
											// console.log(data.url)
											// console.log('data.url != null')
											const loyaltyStatus = "NEW";
											const hash = CryptoJS.SHA1(userPrimaryEmail + LoyaltyID + loyaltyStatus + data.merchantreferencecode);
											const hashString = hash.toString(CryptoJS.enc.Hex);
											const updateRewardsURL = `${MANJA_URL}/Vouchers/${userPrimaryEmail}/${LoyaltyID}`;
											const updateRewardsRequestOptions = {
												"StoreCode": STORE_CODE,
												"Status": loyaltyStatus,
												"RefNo": data.merchantreferencecode,
												"Hash": hashString
											}
											const updateLoyaltyData = async () => {
												try {
													const response = await fetch(
														updateRewardsURL,
														{
															method: 'POST',
															mode: 'cors',
															headers: {
																'apikey': API_KEY,
																'Content-Type': 'application/json'
															},
															body: JSON.stringify(updateRewardsRequestOptions)
														}
													);
													if (!response.ok) {
														throw new Error(
															`This is an HTTP error: The status is ${response.status}`
														);
													}
													let actualData = await response.json();
													if (actualData?.status.status == 200) {

														if (data.url != null && data.url != '') {
															window.location.href = data.url;
														}
														
													}
													else {
														alert("The selected Loyalty voucher is invalid, please try again.");
														// window.location.href = '/';
														setLoading(false);
													}
													// console.log(actualData);
													// console.log(hashString);
													// console.log(updateRewardsURL);

												} catch (err) {
												}
											}
											updateLoyaltyData();
											// console.log(data.merchantreferencecode)
											// window.location.href = data.url;
											// setLoading(false)
										}
										else {
											
											if (data.code !== null && data.msg !== null) {
												// if (data.code === 1) {
												alert("Your selected seat had already been reserved, please wait for 15 minutes for the seat to become available or select another seat to complete the booking.");
												// }
											}
											// window.location.href = '/'
											setLoading(false)
										}
										//`https://sandbox.senangpay.my/payment/162161518585731?detail=${depart} - ${destination}&amount=${data.totalamount}&order_id=${data.merchantreferencecode}&name=${guests[0].guest_name}&email=${primary_guest_email}&phone=${primary_guest_contact}&hash=${data.hash}`;
									}
									);
							} else {
								alert("Selected Loyalty voucher is invalid, please try again.");
								// window.location.reload();
								setLoading(false)
							}

						} catch (err) {
						}
					}
					tokenIsValid()
					getLoyaltyData()
				}
				else {
					// checkout without loyalty points
					fetch(`${SERVER_URL}/order/new`, requestOptions)
						.then(response =>
							response.json()
						)
						.then(data => {

							if (data.url != null && data.url != '') {
								window.location.href = data.url;
							}
							else {
								// alert("Your selected seat had already been reserved, please wait for 15 minutes for the seat to become available or select another seat to complete the booking.");

								if (data.code !== null && data.msg !== null) {
									// console.log('data.Status, Message !== null ')
									// if (data.code === 1) {
									alert("Your selected seat had already been reserved, please wait for 15 minutes for the seat to become available or select another seat to complete the booking.");
									setLoading(false)
									// }
								}
								// window.location.href = '/'
							}
						}
						)
						.catch(err => {
							alert(err);
							window.location.href = '/'
						})
				}

			}
		}
		// localStorage.removeItem("selected_addon_data");
		// sessionStorage.removeItem("busFare");
		// sessionStorage.removeItem("departData");
		// sessionStorage.removeItem("returnData");
	}

	return (
		<>
			{loading && <div className='center'>
				<LoadingScreenModal />
			</div>}
			<h3 className="tnc_text">By clicking ‘{children}’ I acknowledge that I have read and accept the <a href="#" target="_blank"> Rules & Restrictions</a>, <a href="#" target="_blank"> Terms of Use</a> and <a href="#" target="_blank"> Privacy Policy</a>.
			</h3>
			<button
				type="button"
				className="btn--print--ticket--full--width btn--ticket"
				onClick={redirectToPaymentGateway}
			// params={[window.location.search.replace('?', '')]}
			>
				{children}
			</button>

			<Row className='p-4'>
				<Col sm={1}>
					<span className="oval">i</span>
				</Col>
				<Col sm={11}>
					<h4 className="secure_text">We use secure transmission and encrypted storage to protect your personal information.</h4>
				</Col>
			</Row>
		</>
	);
}
