import React, { useContext, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/esm/Row';
import Form from 'react-bootstrap/Form';
import { LoyaltyDiscountContext, LoyaltyIDContext, VoucherCodeContext, VoucherDiscountContext } from '../UserContext';
import { getToken, loyaltyPriceToPoints, tokenIsValid } from './Function';
import './LoyaltyDiscount.css';

export const LoyaltyDiscount = ({
  manjaRewards = 0, primary_guest_email, departure, destination,
  depart_date, return_date,
  bus_fare, login
}) => {



  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const { LoyaltyID, setLoyaltyID } = useContext(LoyaltyIDContext);
  const { TotalLoyalty, setLoyalty } = useContext(LoyaltyDiscountContext);
  const [EnterVoucherCode, setUserEnterVoucherCode] = useState(null);
  const { VoucherCode, setVoucherCode } = useContext(VoucherCodeContext);
  const { VoucherDiscount, setVoucherDiscount } = useContext(VoucherDiscountContext);
  const currency = JSON.parse(localStorage.getItem("currency"));
  const email = JSON.parse(localStorage.getItem("primaryEmail"));



  // console.log(VoucherDiscount)
  function loyalty(value, element) {
    const id = element.getAttribute('id');
    setLoyalty(value);
    setLoyaltyID(id);
    setVoucherCode(null);
    setVoucherDiscount(0);
    var points = loyaltyPriceToPoints(value);
    // console.log(value)
    // console.log(points)
  }
  function setVoucherNo(value) {
    setUserEnterVoucherCode(value);
  }

  function changeCurrencyNaming(curr) {
    switch (curr) {
      case 'MYR': return 'RM';
      default: return curr;
    }
  }
  // function saveIDandValue(id,discount) {
  //   // console.log(id);
  //   setLoyaltyID(id);
  //   return discount;
  // }

  // function checkPoints(value) {
  //   if(value > manjaRewards.points) return true;
  //   else return false;
  // }

  // function divider(value) {
  //   if(value !== 0 && value > manjaRewards.points || value <= manjaRewards.points && manjaRewards.points >= value + 200) return true;
  //   else return false;
  // }
  // // console.log(JSON.parse(localStorage.getItem("LoyaltyID")));
  // console.log(LoyaltyID);
  // console.log(TotalLoyalty);
  function checkVoucherCode(voucher) {
    // console.log(voucher)
    tokenIsValid()
    var el = document.getElementById('invalidCode');
    var el2 = document.getElementById('invalidCurrency');
    el.classList.add('hidden');
    el2.classList.add('hidden');


    //commented by Wei - 20231127
    /*
    const getData = async () => {
      try {
        const response = await fetch(
          `${SERVER_URL}/voucher/${voucher}`,
          {
            headers: {
              'Authorization': getToken()
            }
          }
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        // console.log('loyalty data:');
        // console.log(actualData);
        if (actualData.length != 0) {
          // change MYR to RM
          if (actualData[0].vouchercurrency == changeCurrencyNaming(currency)) {
            // console.log(actualData[0].vouchercurrency == changeCurrencyNaming(currency))
            setVoucherDiscount(parseFloat(actualData[0].vouchervalue));
            setVoucherCode(voucher);
            setLoyalty(0);
            setLoyaltyID(null);
          }
          else {
            el2.classList.remove('hidden');
            setVoucherDiscount(0);
            setVoucherCode(null);
          }
        }
      } catch (err) {
        el.classList.remove('hidden');
        setVoucherDiscount(0);
        setVoucherCode(null);
      }
    }
    */


    const postData = async () => {
      try {
        const response = await fetch(
          `${SERVER_URL}/voucher`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': getToken()
            },
            body: JSON.stringify({
              VoucherCode: voucher,
              Email: primary_guest_email,
              Departure: departure,
              Destination: destination,
              DepartureDate: depart_date,
              ReturnDate: return_date,
              BusFare: bus_fare
            })
          }
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        // console.log('loyalty data:');
        // console.log(actualData);
        if (actualData.length != 0) {
          // change MYR to RM
          if (actualData[0].vouchercurrency == changeCurrencyNaming(currency)) {
            // console.log(actualData[0].vouchercurrency == changeCurrencyNaming(currency))
            setVoucherDiscount(parseFloat(actualData[0].vouchervalue));
            setVoucherCode(voucher);
            setLoyalty(0);
            setLoyaltyID(null);
          }
          else {
            el2.classList.remove('hidden');
            setVoucherDiscount(0);
            setVoucherCode(null);
          }
        }
      } catch (err) {
        // Handle errors as needed
        el.classList.remove('hidden');
        setVoucherDiscount(0);
        setVoucherCode(null);
      }
    };



    postData()
  }


  return (
    <>
      <div className="loyalty-container">
        <table width={'100%'} className='loyalty_discount'>
          <tr>
            <td rowSpan={2}><h1>Loyalty Discount</h1><span className="oval">i</span></td>
          </tr>
          <tr>
            <td><h5>{manjaRewards.points != null && manjaRewards.points != "" ? parseFloat(manjaRewards.points).toFixed() : 0} points</h5><h6>available</h6></td>
          </tr>
        </table>
        <Row className='p-3'>
          <Form.Select className="loyalty_form" id="loyalty_discount_form" name="loyalty_discount_form" onChange={(e) => loyalty(e.target.value, e.target.childNodes[e.target.selectedIndex + 1])}>
            <option class="option_label" disabled>Choose your discount</option>
            <Dropdown.Divider />
            <option value="0" selected>{manjaRewards?.rewards ? "Please select the available discount" : "No loyalty discount"}</option>
            {manjaRewards && manjaRewards.rewards && manjaRewards.rewards.map(({ id, desc, discount, name, seqno }, index) => (
              <option id={id} value={discount}>{name}</option>
            ))}

            {/* <option class='option_label' disabled hidden={divider(0)}> Not enough points </option>
            {divider(0)===false ? <Dropdown.Divider/> : ""}
            <option value="10" disabled={checkPoints(200)}>RM10.00 (-200 points)</option>
            <option class='option_label' disabled hidden={divider(200)}> Not enough points </option>
            {divider(200)===false ? <Dropdown.Divider/> : ""}
            <option value="20" disabled={checkPoints(400)}>RM20.00 (-400 points)</option>
            <option class='option_label' disabled hidden={divider(400)}> Not enough points </option>
            {divider(400)===false ? <Dropdown.Divider/> : ""}
            <option value="30" disabled={checkPoints(600)}>RM30.00 (-600 points)</option>
            <option class='option_label' disabled hidden={divider(600)}> Not enough points </option>
            {divider(600)===false ? <Dropdown.Divider/> : ""}
            <option value="40" disabled={checkPoints(800)}>RM40.00 (-800 points)</option>
            <option class='option_label' disabled hidden={divider(800)}> Not enough points </option>
            {divider(800)===false ? <Dropdown.Divider/> : ""}
            <option value="50" disabled={checkPoints(1000)}>RM50.00 (-1000 points)</option> */}
          </Form.Select>
        </Row>
        <table width={'100%'} className='loyalty_discount'>
          <h1>Do you have a promo code?</h1>
        </table>
        <Row className='p-3 pb-5 content_right'>
          <Form.Control
            className="loyalty_form"
            type="text"
            id="promo_code"
            onChange={(e) => setVoucherNo(e.target.value)}
          />
          <label id='invalidCode' className='hidden'>Invalid Code</label>
          <label id='invalidCurrency' className='hidden'>Invalid currency to apply promo code</label>
          <button onClick={() => checkVoucherCode(EnterVoucherCode)} className='VoucherApply'>Apply</button>
        </Row>
      </div>
    </>
  );
}

export default LoyaltyDiscount;