import React, { useEffect, useState } from 'react';
import '../../App.css';
import CompletedBooking from '../CompletedBooking';
import UpcomingBooking from '../UpcomingBooking';
import { useSearchParams } from 'react-router-dom';
import { getProperDateTime, getToken, getUserPrimaryEmail, setLoginStatus, setUserData, setUserToken, setUserTokenExpiry, tokenIsValid } from '../Function';
import { LoadingScreenModal } from '../LoadingScreenModal';


function ManageBooking() {
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	const [searchParams, setSearchParams] = useSearchParams();
	const token = searchParams.get("key");
	const token_expiry = searchParams.get("expiry");
	const [login, setLogin] = useState(false);
	const [loading, setLoading] = useState(true);
	const [userBookingHistoryData, setUserBookingHistoryData] = useState(null);
	const [upcomingBookingData, setUserUpcomingBookingData] = useState([]);
	const [completedBookingData, setCompletedBookingData] = useState([]);
	const user_email = getUserPrimaryEmail();
	const checkLogin = JSON.parse(localStorage.getItem("login"));

	if (!token && !token_expiry && checkLogin == null) {
		window.location.href = '/';
	}
	useEffect(() => {
		if (checkLogin) {
			setLogin(checkLogin);
		}
	}, [])
	useEffect(() => {
		setLoading(true)
		const getBookingHistoryData = async () => {
			try {
				const response = await fetch(`${SERVER_URL}/order/history/${user_email}`,
					{
						headers: {
							'Authorization': getToken()
						}
					});
				if (!response.ok) {
					throw new Error(
						`This is an HTTP error: The status is ${response.status}`
					);
				}
				let actualData = await response.json();
				setUserBookingHistoryData(actualData);
				// sessionStorage.setItem("subplace_data",JSON.stringify(actualData));
				// console.log(actualData)
			} catch (err) {
				setUserBookingHistoryData(null);
			} finally {
				setLoading(false)
			}
		}
		if (token) {
			const getUserProfileByToken = async () => {
				try {
					const response = await fetch(`${SERVER_URL}/user/profile/token/${token}/${token_expiry}`,
						{
							headers: {
								'Authorization': getToken()
							}
						});
					if (!response.ok) {
						throw new Error(
							`This is an HTTP error: The status is ${response.status}`
						);
					}
					let actualData = await response.json();
					setLoginStatus(true);
					setUserToken(token);
					setUserTokenExpiry(token_expiry);
					setUserData(actualData);
					localStorage.setItem("userData", JSON.stringify(actualData));
					localStorage.setItem("login", JSON.stringify(true));
					localStorage.setItem("primaryEmail", JSON.stringify(actualData.primaryemail));
					if (actualData && !user_email) {
						window.location.reload();
					}
				} catch (err) {
					console.log(err)
				} finally {
					setLoading(false)
				}
			}
			tokenIsValid()
			getUserProfileByToken();
		}
		tokenIsValid()
		getBookingHistoryData()
	}, [getToken()])

	useEffect(() => {
		if (userBookingHistoryData != null && userBookingHistoryData.length != 0) {
			var now = new Date();
			let upcoming = [];
			let completed = [];
			// console.log(userBookingHistoryData)
			
			userBookingHistoryData.forEach(element => {
				if (element.ordernumber != "") {
					// console.log(element.newcartguid)

					var orderDate = getProperDateTime(element.departdate, element.departtime);

					if (element.returndate !== '' && element.returntime !== '' && element.returndate !== null && element.returntime !== null) {
						orderDate = getProperDateTime(element.returndate, element.returntime);
					}
					// console.log(now)
					// console.log(orderDate)
					// console.log(new Date(element.departdate +" "+ element.departtime))
					// console.log(element)
					if (now < orderDate && element.newcartguid == '') {
						// console.log("upcoming booking")
						upcoming.push(element);
						setUserUpcomingBookingData(upcoming);
					}
					else {
						// console.log("completed booking")
						completed.push(element);
						setCompletedBookingData(completed);
					}
				}
			});
			// console.log(upcoming);
			// console.log(completed);
		}
	}, [userBookingHistoryData])

	// console.log(upcomingBookingData);
	// console.log(completedBookingData);
	// console.log(userBookingHistoryData);
	if (login) {
		return (
			<>
				{loading && <div className='center'>
					<LoadingScreenModal />
				</div>}
				<UpcomingBooking
					bookingHistoryData={upcomingBookingData}
				/>
				<CompletedBooking
					bookingHistoryData={completedBookingData}
				/>
			</>
		);
	}
	else {
		return (
			<>
				<h1>Your are not logged in!</h1>
			</>
		)
	}
}

export default ManageBooking;
